// index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Routes, Route } from 'react-router-dom';
import { MMProvider, PrivateLayout } from 'mm-react-tools';
import './index.css';
import App from './App';
import config from './config/default.json';

const root = createRoot(document.getElementById('root'));

root.render(
  <MMProvider
    domain={config.domain}
    clientId={config.clientId}
    clientSecret={config.clientSecret}
    scope={
      'reporting operator user role.any role.operator role.supervisor role.admin role.root'
    }
    releaseStage={config.releaseStage}
  >
    <Routes>
      <Route path="/" element={<PrivateLayout />}>
        <Route index element={<App />} />
      </Route>
    </Routes>
  </MMProvider>
);

import styled from "styled-components";
import { Box, Stepper, Text, Card, Tabs, Step } from "@chakra-ui/react";

//styled div
export const StepperBar = styled.div`
  height: 80px;
  transform: rotate(-90deg);
  flex: 1 0 0;
  background: var(--indigo-600, #5a67d8);
`;

export const LineWrapper = styled.div`
  display: flex;
  width: 160px;
  align-items: flex-start;
`;

export const StyledStep = styled(Step)`
  height: 40px;
  flex: 1 0 0;
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 0px;
  gap: 32px;
  background: #f7fafc;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
`;

export const StyledStepper = styled(Stepper)`
  display: flex;
  align-items: flex-start;
`;

export const StyledInfoBox = styled(Box)`
  border-radius: 4px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 640px;
  border: 1px solid var(--indigo-300, #a3bffa);
  background: var(--indigo-50, #f5faff);
`;

export const StyledText = styled(Text)`
  color: var(--grey-700, #4a5568);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.048px;
  font-family: var(--chakra-fonts-body);
`;

export const StyledCard = styled(Card)`
  border-radius: 4px;
  border: 1px solid var(--grey-400, #cbd5e0);
  background: var(--grey-00, #fff);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  max-width: 60vh;
`;
export const StyledTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  max-height: 60vh;
  min-height: 60vh;
  overflow: auto;
`;
export const StyledFooter = styled(Box)`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;
`;

export const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

export const StyledImportBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
`;

export const StyledImportSummary = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--grey-400, #cbd5e0);
`;

export const StyledImportSummaryItem = styled(Box)`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-300, #e2e8f0);
  }
`;

export const StyledContentBox = styled(Box)`
  border-radius: 4px;
  border: 1px solid var(--grey-400, #cbd5e0);
  background: var(--grey-00, #fff);
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 70vw;
  margin: auto;
  gap: 24px;
`;

export function msToHMS(ms) {
    let seconds = ms / 1000;

    let hours = parseInt(seconds / 3600);
    seconds = seconds % 3600;

    let minutes = parseInt(seconds / 60);
    seconds = seconds % 60;

    let duration = [];

    if (hours > 0) {
      duration.push(`${hours}h`);
    }
    if (Math.floor(minutes) > 0) {
      duration.push(`${minutes}m`);
    }
    if (Math.floor(seconds) > 0) {
      duration.push(`${Math.floor(seconds)}s`);
    }

    return duration.join(" ");
  }
import axios from "axios";
import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { msToHMS } from "./utils/helperFuncs";
import {
  StyledBox,
  StyledInfoBox,
  StyledText,
  StyledTabs,
  StyledFooter,
  StyledButtonContainer,
  StyledImportBox,
  StyledImportSummary,
  StyledImportSummaryItem,
  StyledContentBox,
} from "./components/styledComponents";
import {
  Box,
  Button,
  Checkbox,
  ChakraProvider,
  extendTheme,
  Flex,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  TabPanel,
  TabPanels,
  useToast,
  VStack,
  Center,
} from "@chakra-ui/react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useMMAuth, _useMMAuthInternal } from "mm-react-tools";
import TimePicker from "react-time-picker";
import config from './config/default.json';

const theme = extendTheme({
  /*   fonts: {
    heading: "Noto Sans",
    body: "Noto Sans",
  }, */
  colors: {
    bgGrey: "#f7fafc",
    bgWhite: "#ffffff",
    customIndigo: {
      500: "#5A67D8", // The primary color
    },
  },
  components: {
    Button: {
      baseStyle: {
        color: "white",
        _hover: {
          bg: "customIndigo.500",
        },
      },
    },
    Radio: {
      baseStyle: {
        _focus: {
          boxShadow: "0 0 0 2px #5A67D8", // This will change the outline color
        },
      },
    },
  },
});

const steps = [
  {
    title: "Map Machines",
    buttonLabel: "Save Mapping",
    instructions:
      "Map your Workcenters in your ERP to their equivalent Machines in MachineMetrics.",
  },
  {
    title: "Map Machine Groups",
    buttonLabel: "Save Mapping",
    instructions:
      "Map the Resource Groups from your ERP to their equivalent Machine Groups in MachineMetrics. Only mapped Machine Groups will have their operations imported.",
  },
  {
    title: "Review Operations",
    buttonLabel: "Confirm Operations",
    instructions:
      "The table below displays the operations that will be imported into MachineMetrics along with their part, expected cycle time, expected setup time, and the machine group they are available on. Any matching operations in MachineMetrics will be overwritten with the imported data.",
  },
  {
    title: "Set Schedule & Import",
    buttonLabel: "Save & Import",
    instructions:
      'Configure your import schedule and review your integration. All actions below will be taken once you click "Finish."',
  },
];

function App() {
  const { request, urls } = useMMAuth();

  const [erpMachines, setERPMachines] = useState([]);
  const [erpMachineGroups, seterpMachineGroups] = useState([]);
  const [operations, setOperations] = useState([]);

  const [machineGroups, setMachineGroups] = useState([]);
  const [machines, setMachines] = useState([]);

  const [company, setCompany] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  //States to manage what's selected by the dropdowns
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedMachineGroups, setSelectedMachineGroups] = useState([]);

  //States to manage import options
  const [importFrequency, setImportFrequency] = useState("Manually");
  const [importImmediately, setImportImmediately] = useState(false);

  const [isToastShowing, setToastShowing] = useState(false);

  const [isLoadingMachines, setIsLoadingMachines] = useState(false);
  const [isLoadingMachineGroups, setIsLoadingMachineGroups] = useState(false);
  const [isLoadingOperations, setIsLoadingOperations] = useState(false);

  const [isFinishing, setIsFinishing] = useState(false);

  const [scheduleTime, setScheduleTime] = useState("10:00");

  const [mapping, setMapping] = useState({
    config: {
      mapping: {
        machines: [],
        machineGroups: [],
      },
    },
  });
  const [configurationChanges, setConfigurationChanges] = useState([]);

  const { jwt } = _useMMAuthInternal();

  const query = gql`
    query {
      companies {
        name
      }
    }
  `;

  const { data, loading } = useQuery(query);

  const toast = useToast();

  const erpRequest = async (
    endpoint,
    setData,
    errorHandler,
    method = "GET",
    body = null
  ) => {
    try {
      const response = await axios({
        method: method,
        url: `${config.erpUrl}/${endpoint}`,
        data: body,
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setData && setData(response.data);
      return response.data;
    } catch (error) {
      console.error(`Error ${method} ${endpoint}: ${error.message}`);
      errorHandler && errorHandler();

      // If the error message is "Company secrets missing", show a toast
      if (
        endpoint === "machines" &&
        !isToastShowing &&
        error.response &&
        error.response.data === "Company secrets missing"
      ) {
        setToastShowing(true);
        toast({
          title: "Error",
          description:
            "The credentials for this integration are missing or incorrect. Please reconfigured the integration from the Integrations page.",
          status: "error",
          duration: 9000,
          isClosable: true,
          onCloseComplete: () => setToastShowing(false), // Reset isToastShowing when the toast closes
        });
      }
    }
  };

  useEffect(() => {
    const handleMappingError = () => {
      setMapping(mapping);
    };

    const handleMappingSuccess = (data) => {
      const machines = data.config.mapping.machines;
      const machineGroups = data.config.mapping.machineGroups;

      setSelectedMachines(machines);
      setSelectedMachineGroups(machineGroups);

      // If there is no mapping, set mapping to default state
      setMapping(data ? data : mapping);
    };

    erpRequest("machines", setERPMachines, () => setERPMachines([]));
    erpRequest("machine-groups", seterpMachineGroups, () =>
      seterpMachineGroups([])
    );
    erpRequest("mapping", handleMappingSuccess, handleMappingError);
  }, []);

  const mmRequest = async (
    endpoint,
    method,
    body,
    setData,
    errorHandler = () => console.error(`Error ${method} ${endpoint}`)
  ) => {
    try {
      const response = await request(`${urls.apiUrl}/${endpoint}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: body ? JSON.stringify(body) : undefined,
      });
      if (setData) setData(response);
      return response;
    } catch (error) {
      console.error(`Error ${method} ${endpoint}: ${error.message}`);
      errorHandler();
      return null;
    }
  };

  const fetchMachineGroups = () => {
    mmRequest(
      "machine-groups?includeAnnoTypes=1",
      "GET",
      null,
      setMachineGroups,
      () => setMachineGroups([])
    );
  };

  const createNewMachineGroupRequest = (machineGroup, machineIds) => {
    if (!machineGroup || !machineIds) {
      console.error("Machine group object and machine Ids are required");
      return null;
    }

    let body = {
      name: machineGroup,
      machines: machineIds,
      annotationTypes: [],
    };

    return mmRequest("machine-groups/", "POST", body);
  };

  const updateMachineGroupRequest = (group) => {
    if (!group.id || !group.machines) {
      console.error("Machine group id and machines are required");
      return null;
    }

    const annotationTypes = group.downtimeReasons.map((reason) => reason.id);

    const body = {
      name: group.name,
      machines: group.machines,
      erpGroupId: group.erpGroupId,
      annotationTypes: annotationTypes,
    };

    return mmRequest(`machine-groups/${group.id}`, "PUT", body);
  };

  useEffect(() => {
    mmRequest("machines", "GET", null, setMachines, () => setMachines([]));
    fetchMachineGroups();
  }, []);

  const createWorkflow = (schedule) => {
    const body = {
      time: schedule.time || null,
      byweekday: schedule.byweekday || null,
    };

    erpRequest("createWorkflow", null, null, "POST", body);
  };

  const fetchERPOperations = async () => {
    setIsLoadingOperations(true);
    const mappedMachineGroups = selectedMachineGroups.map((machineGroup) =>
      String(machineGroup.erpGroupName)
    );

    await erpRequest(
      `operations?limit=300&erpMachineGroups=${mappedMachineGroups}`,
      setOperations,
      () => setOperations([])
    );
    setIsLoadingOperations(false);
  };

  const importOperations = () => {
    //No state set or error handling here.
    erpRequest(
      "importOperations",
      () => {},
      () => {},
      "POST"
    );
  };

  const createNewMachineRequest = (machine) => {
    if (!machine) {
      console.error("Machine object is required");
      return null;
    }

    const body = {
      name: machine.machineERPName,
      machineModel: machine.machineModel || "Unknown",
      machineMake: machine.machineMake || "Unknown",
      machineCategoryId: 1,
    };

    return mmRequest("temporary-v2/machines", "POST", body);
  };

  // Update template
  const updateIntegrationConfiguration = async (updatedMachines, newGroups) => {
    const mappingObject = {
      machines: updatedMachines,
      machineGroups: newGroups,
    };

    try {
      // Fetch the existing mapping
      const existingMapping = await erpRequest(
        "mapping",
        (data) => data,
        () => {}
      );

      if (existingMapping) {
        // If a mapping exists, update it
        await erpRequest(
          "mapping",
          (data) => {
            console.log("Mapping updated successfully: ", data);
          },
          () => {},
          "PUT",
          mappingObject
        );
      } else {
        console.log("No existing mapping found. Creating new mapping...");
        // If a mapping does not exist, create it
        await erpRequest(
          "mapping",
          () => {},
          () => {},
          "POST",
          mappingObject
        );
      }

      toast({
        title: "Integration Updated.",
        description: "Your integration settings were successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error updating the mapping: ${error.message}`);
    }
  };

  // Event handler for when a dropdown is changed. This needs to be refactored as it's a mess.
  const handleSelectMachineChange = (
    machineERPId,
    selectedMachineId,
    selectedMachineGroup,
    selectedMachineName
  ) => {
    setSelectedMachines((prevMachines) => {
      let machineExists = false;

      const updatedMachines = prevMachines.reduce((newMachines, machine) => {
        if (machine.machineERPId === machineERPId) {
          machineExists = true;

          if (selectedMachineId) {
            // If selectedMachineId exists, update the machine
            newMachines.push({
              ...machine,
              machineId: selectedMachineId,
              machineERPName: selectedMachineName,
              selectedMachineGroup,
            });
          }
          // If selectedMachineId does not exist, remove the machine by not pushing it to newMachines
        } else {
          // If the machine does not match, keep it as is
          newMachines.push(machine);
        }

        return newMachines;
      }, []);

      if (!machineExists && selectedMachineId) {
        // If the machine does not exist in state, and selectedMachineId is not 'none' or undefined, add it
        updatedMachines.push({
          machineId: selectedMachineId,
          machineERPId,
          selectedMachineGroup,
          machineERPName: selectedMachineName,
          newMachine: selectedMachineId === "newMachine",
        });
      }

      return updatedMachines;
    });
  };

  const isMachineSelectedAlready = (machineERPId, optionId) => {
    return selectedMachines.some(
      (machine) =>
        machine.machineERPId !== machineERPId && machine.machineId === optionId
    );
  };

  const handleSelectMachineGroupChange = (
    groupERPId,
    selectedGroup,
    erpGroupName
  ) => {
    // Lookup the group name from the selectedGroup value in the machineGroups array
    const mmGroupName = machineGroups.find(
      (group) => group.id === selectedGroup
    )?.name;

    setSelectedMachineGroups((prevGroups) => {
      let groupExists = false;

      const updatedGroups = prevGroups.reduce((newGroups, group) => {
        if (group.groupERPId === groupERPId) {
          groupExists = true;

          if (selectedGroup) {
            // If selectedGroup exists, update the group
            newGroups.push({
              ...group,
              groupId: selectedGroup,
              erpGroupName,
              mmGroupName,
              newGroup: selectedGroup === "newGroup",
            });
          }
          // If selectedGroup does not exist, remove the group by not pushing it to newGroups
        } else {
          // If the group does not match, keep it as is
          newGroups.push(group);
        }

        return newGroups;
      }, []);

      if (!groupExists && selectedGroup) {
        // If the group does not exist in state, and selectedGroup is not 'none' or undefined, add it
        updatedGroups.push({
          groupId: selectedGroup,
          groupERPId,
          erpGroupName,
          mmGroupName,
          newGroup: selectedGroup === "newGroup",
        });
      }

      return updatedGroups;
    });
  };

  const isGroupSelectedAlready = (groupERPId, optionId) => {
    return selectedMachineGroups.some(
      (group) => group.groupERPId !== groupERPId && group.groupId === optionId
    );
  };

  const compareMappingState = (
    initialMapping,
    selectedMachines,
    selectedMachineGroups
  ) => {
    const updates = [];

    //Compare selected machines vs initial mapping
    selectedMachines.forEach((machine) => {
      const initialMachine = initialMapping.config.mapping.machines.find(
        (m) => m.machineERPId === machine.machineERPId
      );
      if (!initialMachine || initialMachine.machineId !== machine.machineId) {
        updates.push({
          type: "machine",
          machineERPId: machine.machineERPId,
          newMachineId: machine.machineId,
        });
      }
    });

    // Compare selected machine groups vs initial mapping
    selectedMachineGroups.forEach((group) => {
      const initialGroup = initialMapping.config.mapping.machineGroups.find(
        (g) => g.groupERPId === group.groupERPId
      );
      if (!initialGroup || initialGroup.groupId !== group.groupId) {
        updates.push({
          type: "group",
          groupERPId: group.groupERPId,
          newGroupId: group.groupId,
        });
      }
    });

    return updates;
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const handleNext = () => {
    if (tabIndex < 3)
      // Assuming you have 4 tabs
      setTabIndex(tabIndex + 1);
    setConfigurationChanges(
      compareMappingState(mapping, selectedMachines, selectedMachineGroups)
    );

    //This needs to get moved to someplace where adding new tabs won't break it
    if (tabIndex === 1) {
      fetchERPOperations();
    }
  };

  const handleBack = () => {
    if (tabIndex > 0) setTabIndex(tabIndex - 1);
  };

  const createNewMachines = async () => {
    const newMachines = selectedMachines.filter(
      (machine) => machine.newMachine === true
    );
  
    let updatedMachines = [...selectedMachines];
  
    const machinePromises = newMachines.map(async (machine) => {
      const newMachine = await createNewMachineRequest(machine);
    
      const machineIndex = updatedMachines.findIndex(
        (m) => m.machineERPId === machine.machineERPId
      );
      updatedMachines[machineIndex] = {
        ...updatedMachines[machineIndex],
        machineId: newMachine.id,
        newMachine: false,
      };
    });
  
    await Promise.all(machinePromises);
  
    setSelectedMachines(updatedMachines);
  
    return updatedMachines;
  };

  const createNewMachineGroups = async (updatedMachines) => {
    const newGroups = selectedMachineGroups.filter(
      (group) => group.newGroup === true
    );
  
    let updatedGroups = [...selectedMachineGroups];
  
    const groupPromises = newGroups.map(async (group) => {
      // Find all machines in this group and create array of machineIds
      const machinesInGroup = updatedMachines.filter(
        (machine) => machine.selectedMachineGroup === group.erpGroupName
      );
  
      const machineIds = machinesInGroup.map((machine) => machine.machineId);
  
      const newGroup = await createNewMachineGroupRequest(
        group.erpGroupName,
        machineIds
      );
  
      const groupIndex = updatedGroups.findIndex(
        (g) => g.groupERPId === group.groupERPId
      );
      updatedGroups[groupIndex].groupId = newGroup.id;
      updatedGroups[groupIndex].newGroup = false;
      updatedGroups[groupIndex].mmGroupName = group.erpGroupName;
    });
  
    await Promise.all(groupPromises);
  
    setSelectedMachineGroups(updatedGroups);
    
    return updatedGroups;
  };  

  // Updating existing groups with newly mapped machines. Another opportunity ripe for refactor.
  const updateExistingMachineGroups = async (updatedMachines, newGroups) => {
    
    const existingGroups = newGroups ? newGroups.filter(
      (group) => group.newGroup === false
    ) : [];

    // Check the machineGroups state and return only the groups in existingGroups
    const existingMachineGroups = machineGroups.filter((group) => {
      return existingGroups.some(
        (existingGroup) => existingGroup.groupId === group.id
      );
    });

    // For each item in existingMachineGroups, find the corresponding item in selectedMachineGroups to get the name
    // Use that name to map which machines belong to the mapped group
    const existingMachineGroupsWithMachines = existingMachineGroups.map(
      (group) => {
        const erpGroupName = newGroups.find(
          (g) => g.mmGroupName === group.name
        ).erpGroupName;

        if( updatedMachines && updatedMachines.length === 0 ) return group;

        const machinesInGroup = updatedMachines.filter(
          (machine) => machine.selectedMachineGroup === erpGroupName
        );
        const machineIds = machinesInGroup.map((machine) => machine.machineId);
        return { ...group, machines: machineIds };
      }
    );

    const groupPromises = existingMachineGroupsWithMachines.map(
      async (group) => {
        const updatedGroup = await updateMachineGroupRequest(group);
      }
    );

    await Promise.all(groupPromises);
  };

  const handleFinish = async () => {
    setIsFinishing(true);
  
    const updatedMachines = await createNewMachines();
      
    const newGroups = await createNewMachineGroups(updatedMachines);
    await updateExistingMachineGroups(updatedMachines, newGroups);
    await updateIntegrationConfiguration(updatedMachines, newGroups);
  
    if (importImmediately || importFrequency === "Manually") {
      await importOperations();
    }
  
    if (importFrequency === "Daily") {
      await createWorkflow({
        time: scheduleTime,
        byweekday: null});
    }
  
    setIsFinishing(false);
  };

  return (
    <React.Fragment>
      <ChakraProvider theme={theme}>
        <Box bg="bgGrey">
          <Heading as="h2" size="xl" m={4}>
            JobBOSS
            <sup>2 </sup>
            Settings
          </Heading>
        </Box>
        <StyledBox>
          <Stack>
            <Stepper size="sm" colorScheme="customIndigo" index={tabIndex}>
              {steps.map((step, index) => (
                <Step key={index} gap="0">
                  <Flex flexDirection="column" alignItems="flex-start">
                    <Box minWidth="100%">
                      <Flex flexDirection="column" alignItems="center">
                        <StepIndicator>
                          <StepStatus
                            active={<StepNumber />}
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                          />
                        </StepIndicator>
                        <StepTitle textAlign="center" width="150px">
                          {step.title}
                        </StepTitle>
                      </Flex>
                    </Box>
                  </Flex>
                  <Box flexShrink="0">
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                  <StepSeparator minWidth="5vw" />
                </Step>
              ))}
            </Stepper>
          </Stack>
          <StyledInfoBox>
            <StyledText>{steps[tabIndex].instructions}</StyledText>
          </StyledInfoBox>
          <StyledTabs index={tabIndex} onChange={handleTabsChange}>
            <TabPanels>
              <TabPanel padding={"0px"}>
                <StyledContentBox>
                  <Table size="sm" variant="simple">
                    <Thead>
                      <Tr bg="bgGrey" height="5vh">
                        <Th>
                          JobBOSS
                          <sup>2 </sup>
                          Workcenter
                        </Th>
                        <Th textAlign="left">MachineMetrics Machine</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {erpMachines.map((erpMachine) => (
                        <Tr key={erpMachine.erpId}>
                          <Td>{erpMachine.name}</Td>
                          <Td>
                            <Select
                              placeholder="Select a machine"
                              width="50%"
                              value={
                                selectedMachines.find(
                                  (machine) =>
                                    machine.machineERPId === erpMachine.erpId
                                )?.machineId
                              }
                              onChange={(event) =>
                                handleSelectMachineChange(
                                  erpMachine.erpId,
                                  event.target.value,
                                  erpMachine.machineGroup,
                                  erpMachine.name
                                )
                              }
                            >
                              {machines &&
                                machines.map((machineOption) => (
                                  <option
                                    key={machineOption.id}
                                    value={machineOption.id}
                                    disabled={isMachineSelectedAlready(
                                      erpMachine.erpId,
                                      machineOption.id
                                    )}
                                  >
                                    {machineOption.name}
                                  </option>
                                ))}
                              <option key="newMachine" value="newMachine">
                                Add New Machine
                              </option>
                            </Select>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </StyledContentBox>
              </TabPanel>
              <TabPanel padding={"0px"}>
                <StyledContentBox>
                  <Table size="sm" variant="simple">
                    <Thead>
                      <Tr bg="bgGrey" height="5vh">
                        <Th width="180px">
                          {" "}
                          JobBOSS
                          <sup>2 </sup>
                          Department
                        </Th>
                        <Th width="180px" textAlign="left">
                          Machine Group
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {erpMachineGroups.map((machineGroup) => {
                        // Check if there is a match in selectedMachines
                        const isMatch = selectedMachines.some(
                          (machine) =>
                            machine.selectedMachineGroup ===
                            machineGroup.machineGroup
                        );
                        // If there is no match, return null
                        if (!isMatch) {
                          return null;
                        }
                        return (
                          <Tr key={machineGroup.erpId}>
                            <Td>{machineGroup.machineGroup}</Td>
                            <Td>
                              <Select
                                placeholder="Select a machine group"
                                width="50%"
                                value={
                                  selectedMachineGroups.find(
                                    (group) =>
                                      group.groupERPId === machineGroup.erpId
                                  )?.groupId
                                }
                                onChange={(event) =>
                                  handleSelectMachineGroupChange(
                                    machineGroup.erpId,
                                    event.target.value,
                                    machineGroup.machineGroup
                                  )
                                }
                              >
                                {machineGroups &&
                                  machineGroups.map((groupOption) => (
                                    <option
                                      key={groupOption.id}
                                      value={groupOption.id}
                                      disabled={isGroupSelectedAlready(
                                        machineGroup.erpId,
                                        groupOption.id
                                      )}
                                    >
                                      {groupOption.name}
                                    </option>
                                  ))}
                                <option key="newGroup" value="newGroup">
                                  Create New Machine Group
                                </option>
                              </Select>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </StyledContentBox>
              </TabPanel>
              <TabPanel padding={"0px"}>
                <StyledContentBox>
                  {isLoadingOperations ? (
                    <Center>
                      <Spinner
                        thickness="4px"
                        emptyColor="gray.200"
                        color="gray.300"
                        size="xl"
                      />
                    </Center>
                  ) : (
                    <Table size="sm" variant="simple">
                      <Thead>
                        <Tr bg="bgGrey" height="5vh">
                          <Th width="200px">Part</Th>
                          <Th width="200px">Operation</Th>
                          <Th textAlign="right" width="200px">
                            Expected Cycle Time
                          </Th>
                          <Th textAlign="right" width="200px">
                            Expected Setup
                          </Th>
                          <Th textAlign="left" width="200px">
                            Machine Group
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {operations.map((operation, index) => (
                          <Tr key={index}>
                            <Td>{operation.name}</Td>
                            <Td>{operation.operation}</Td>
                            <Td textAlign="right">
                              {msToHMS(operation.default_expectedparttime)}
                            </Td>
                            <Td textAlign="right">
                              {msToHMS(operation.default_setuptime)}
                            </Td>
                            <Td textAlign="left">{operation.workcenter}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  )}
                </StyledContentBox>
              </TabPanel>
              <TabPanel padding={"0px"}>
                <StyledContentBox marginBottom={"16px"} maxWidth="80vh">
                  <StyledImportBox>
                    <Heading size="lg" mb={5}>
                      Operation Import Schedule
                    </Heading>
                    <Box mb={5}>
                      <Heading size="md" mb={2}>
                        Frequency
                      </Heading>
                      <RadioGroup
                        colorScheme="customIndigo"
                        onChange={(value) => setImportFrequency(value)}
                        value={importFrequency}
                      >
                        <HStack spacing={5}>
                          <Radio value="Manually">Manually</Radio>
                          {/*                           <Radio value="Hourly">Hourly</Radio>
                           */}{" "}
                          <Radio value="Daily">Daily</Radio>
                          {/*                           <Radio value="Weekly">Weekly</Radio>
                           */}{" "}
                        </HStack>
                      </RadioGroup>
                    </Box>
                    {importFrequency !== "Manually" && (
                      <Checkbox
                        colorScheme="customIndigo"
                        value={importImmediately}
                        onChange={() =>
                          setImportImmediately(!importImmediately)
                        }
                      >
                        Start initial import immediately
                      </Checkbox>
                    )}
                  </StyledImportBox>
                </StyledContentBox>
                <StyledContentBox maxWidth="80vh">
                  <StyledImportBox>
                    <Heading size="lg" mb={5}>
                      Import Summary
                    </Heading>
                    <StyledImportSummary>
                      <StyledImportSummaryItem>
                        {
                          configurationChanges.filter(
                            (change) => change.type === "machine"
                          ).length
                        }{" "}
                        Machines will be mapped (
                        {
                          selectedMachines.filter(
                            (machine) => machine.newMachine === true
                          ).length
                        }{" "}
                        new Machines will be created)
                      </StyledImportSummaryItem>
                      <StyledImportSummaryItem>
                        {
                          configurationChanges.filter(
                            (change) => change.type === "group"
                          ).length
                        }{" "}
                        Machine Groups will be mapped (
                        {
                          selectedMachineGroups.filter(
                            (group) => group.newGroup === true
                          ).length
                        }{" "}
                        Machine Groups will be created)
                      </StyledImportSummaryItem>
                      <StyledImportSummaryItem>
                        {operations.length} Operations will be imported
                      </StyledImportSummaryItem>
                    </StyledImportSummary>
                  </StyledImportBox>
                </StyledContentBox>
              </TabPanel>
            </TabPanels>
          </StyledTabs>
          <StyledFooter>
            <StyledButtonContainer>
              {tabIndex > 0 && (
                <Button m={2} bg="white" variant="outline" onClick={handleBack}>
                  Previous
                </Button>
              )}
              {tabIndex < steps.length - 1 && (
                <Button m={2} colorScheme="customIndigo" onClick={handleNext}>
                  Next
                </Button>
              )}
              {tabIndex === steps.length - 1 && (
                <Button
                  m={2}
                  colorScheme="customIndigo"
                  onClick={handleFinish}
                  isDisabled={isFinishing}
                  isLoading={isFinishing}
                >
                  Finish
                </Button>
              )}
            </StyledButtonContainer>
          </StyledFooter>
        </StyledBox>
      </ChakraProvider>
    </React.Fragment>
  );
}

export default App;
